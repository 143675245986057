<template>
  <div>
    <b-card v-if="Case[0]">
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="activeUserInfo.userRole !== 'readonly admin' && activeUserInfo.userRole !== 'read'"
            v-b-tooltip.hover="'Request Update'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="RequestUpdate()"
          >
            <span class="align-middle">Request Update</span></b-button>
        </div>
        <div>
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Refresh()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>
        </div>
      </b-button-toolbar>
      <b-row>
        <b-col md="3">
          <label><b><i>Case Number:</i></b></label>
          <b-form-input
            v-model="Case[0].caseNumber"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Case Type:</i></b></label>
          <b-form-input
            v-model="Case[0].caseType"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Case Name:</i></b></label>
          <b-form-input
            v-model="Case[0].officialCaseName"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Status:</i></b></label>
          <b-form-input
            v-model="Case[0].status"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <label><b><i>Related Case Number:</i></b></label>
          <b-form-input
            v-model="Case[0].relatedCaseNumber"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="4">
          <label><b><i>Description:</i></b></label>
          <b-form-input
            v-model="Case[0].description"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="2">
          <label><b><i>Country:</i></b></label>
          <b-form-input
            v-model="Case[0].countryCode"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Effective Date:</i></b></label>
          <b-form-input
            v-model="Case[0].effectiveDate"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <label><b><i>Manufacturer ID:</i></b></label>
          <b-form-input
            v-model="Case[0].manufacturerID"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Manufacturer Name:</i></b></label>
          <b-form-input
            v-model="Case[0].manufacturerName"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Foreign Exporter ID:</i></b></label>
          <b-form-input
            v-model="Case[0].foreignExporterID"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Foreign Exporter Name:</i></b></label>
          <b-form-input
            v-model="Case[0].foreignExporterName"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <div class="divider my-2">
        <div class="divider-text">
          Official Contact Info
        </div>
      </div>
      <b-row>
        <b-col md="3">
          <label><b><i>Offical Contact:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContact"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Official Name:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContactName"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Tel1:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContactTelphone"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Ext1:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContactTelExt"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="3">
          <label><b><i>Tel2:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContactTelphone2"
            v-uppercase
            readonly
          />
        </b-col>
        <b-col md="3">
          <label><b><i>Ext2:</i></b></label>
          <b-form-input
            v-model="Case[0].officeContactTelExt2"
            v-uppercase
            readonly
          />
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col md="12">
          <b-tabs>
            <b-tab
              v-if="Events"
              title="Events"
            >
              <b-table
                v-if="Events"
                :fields="Eventsfields"
                :items="Events"
                responsive=""
                hover
              />
            </b-tab>
            <b-tab
              v-if="Tariffs"
              title="Tariffs"
            >
              <b-table
                v-if="Tariffs"
                :fields="Tariffsfields"
                :items="Tariffs"
                responsive=""
                hover
              />
            </b-tab>
            <b-tab
              v-if="Rate"
              title="Rate"
            >
              <b-table
                v-if="Rate"
                :fields="Ratefields"
                :items="Rate"
                responsive=""
                hover
              />
            </b-tab>
            <b-tab
              v-if="Cashbond"
              title="Cash/Bond"
            >
              <b-table
                v-if="Cashbond"
                :fields="Cashbondfields"
                :items="Cashbond"
                responsive=""
                hover
              />
            </b-tab>
            <b-tab
              v-if="Liquidation"
              title="Liquidation Info"
            >
              <b-table
                v-if="Liquidation"
                :fields="Liquidationfields"
                :items="Liquidation"
                responsive=""
                hover
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  data() {
    return {
      casenumber: this.$route.params.casenumber,
      Case: [],
      Cashbond: [],
      Events: [],
      Liquidation: [],
      Tariffs: [],
      Rate: [],
      Tariffsfields: [
        { key: 'tariffNumber', lable: 'Tariff Number' },
        { key: 'addedDate', lable: 'Added Date' },
        { key: 'inactivatedDate', lable: 'Inactivate Date' },
      ],
      Eventsfields: [
        { key: 'event', lable: 'Event' },
        { key: 'determination', lable: 'Determination' },
        { key: 'frCitation', lable: 'FR Citation' },
        { key: 'addedDate', lable: 'Added Date' },
        { key: 'inactivatedDate', lable: 'Inactivate Date' },
      ],
      Ratefields: [
        { key: 'adValoremRate', lable: 'Ad Valorem Rate' },
        { key: 'specificRate', lable: 'Specific Rate' },
        { key: 'uom', lable: 'UOM' },
        { key: 'depositRateEffectiveDate', lable: 'Effective Date' },
        { key: 'rateAddedDate', lable: 'Added Date' },
        { key: 'rateInactivatedDate', lable: 'Inactivate Date' },
      ],
      Cashbondfields: [
        { key: 'bondCashIndicator', lable: 'Bond/Cash Indicator' },
        { key: 'bondCashEffectiveDate', lable: 'Effective Date' },
        { key: 'addedDate', lable: 'Added Date' },
        { key: 'inactivatedDate', lable: 'Inactivate Date' },
      ],
      Liquidationfields: [
        { key: 'action', lable: 'Action' },
        { key: 'actionEffectiveDate', lable: 'Effective Date' },
        { key: 'actionAddedDate', lable: 'Added Date' },
        { key: 'actionInactivatedDate', lable: 'Inactivate Date' },
      ],
    }
  },
  computed: {
    isLoggedIn() { return this.$store.getters.user.isLoggedIn },
    activeUserInfo() {
      return this.$store.state.user.AppActiveUser
    },
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.ABIDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      return Promise.all([this.LoadingStart(), this.LoadCase(), this.LoadCashBond(), this.LoadEvents(),
        this.LoadLiquidation(), this.LoadTariff(), this.LoadRate()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadCase() {
      return axios.get(`/abi/query/adcvd/${this.casenumber}`)
        .then(resp => {
          this.Case = resp.data
        })
    },
    LoadCashBond() {
      return axios.get(`/abi/query/adcvd/bondcash/${this.casenumber}`)
        .then(resp => {
          this.Cashbond = resp.data
        })
    },
    LoadEvents() {
      return axios.get(`/abi/query/adcvd/events/${this.casenumber}`)
        .then(resp => {
          this.Events = resp.data
        })
    },
    LoadLiquidation() {
      return axios.get(`/abi/query/adcvd/liquidation/${this.casenumber}`)
        .then(resp => {
          this.Liquidation = resp.data
        })
    },
    LoadTariff() {
      return axios.get(`/abi/query/adcvd/tariff/${this.casenumber}`)
        .then(resp => {
          this.Tariffs = resp.data
        })
    },
    LoadRate() {
      return axios.get(`/abi/query/adcvd/rate/${this.casenumber}`)
        .then(resp => {
          this.Rate = resp.data
        })
    },
    Refresh() {
      this.LoadAll()
    },
    RequestUpdate() {
      this.LoadingStart()
      const ADCVDCaseQuery = {
        Case1: this.casenumber,
        Case2: '',
        Case3: '',
        Case4: '',
        Case5: '',
        Case6: '',
        Case7: '',
        Case8: '',
        Case9: '',
        Case10: '',
      }

      axios.post('/abi/query/adcvd/case', ADCVDCaseQuery)
        .then(() => {
          this.LoadingEnd()
          this.DisplaySuccess('Update requested.').then(() => {
            this.LoadAll()
          })
        })
        .catch(e => {
          this.LoadingEnd()
          this.DisplayError('Update cannot be requested.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
        })
    },
  },
}
</script>
